body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}

body ::-webkit-scrollbar-track {
  background: $base-50;
  border-radius: 0;
}

body ::-webkit-scrollbar-thumb {
  cursor: pointer;
  border-radius: 0;
  background: $slate-80;
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}

body ::-webkit-scrollbar-thumb:window-inactive {
  background: $slate-90;
}

body ::-webkit-scrollbar-thumb:hover {
  background: $slate-70;
}
