$stale-color: #78711c;
$even-color: rgba(153, 153, 153, 0.15);

.custom-grid {
  /* GLOBAL */
  --ag-foreground-color: #fffff2;
  --ag-background-color: #12141a;
  --ag-background-color-secondary: #26282d;
  --ag-font-family: Noto Sans;
  --ag-grid-size: 3px;
  --ag-borders: none;
  --ag-font-size: 11px;

  /* HEADER */
  --ag-header-foreground-color: #999999;
  --ag-header-background-color: #12141a;
  --ag-header-column-separator-display: 'block';
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: rgba(1, 1, 1, 0.25);

  /* COLUMN */
  --ag-column-hover-color: rgba(245, 247, 215, 0.15);

  /* ROW */
  --ag-row-hover-color: rgba(245, 247, 215, 0.15);
  --ag-row-border-style: solid;
  --ag-row-border-width: 1px;
  --ag-row-border-color: rgba(1, 1, 1, 0.25);
  --ag-odd-row-background-color: --ag-background-color;

  /* CELL */
  --ag-cell-horizontal-border: solid rgba(1, 1, 1, 0.25);
  --ag-range-selection-border-color: #4189e8;
  --ag-value-change-value-highlight-background-color: #01a3a4;

  .ag-root-wrapper {
    border: none;
  }

  .ag-row-even {
    background-color: $even-color;
  }

  .ag-tooltip-custom {
    position: fixed;
    z-index: 9999;
  }

  .ag-tenor-column {
    color: var(--ag-header-foreground-color);
    text-align: left;
    padding-left: 10px;
    font-weight: 700;
  }

  .ag-pinned-left-cols-container .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell):not(:focus) {
    border-right: 3px var(--ag-cell-horizontal-border);
  }

  .ag-trading-buy-cell {
    padding-right: 10px;
  }

  .ag-trading-sell-cell {
    padding-left: 10px;
  }

  .ag-header-even,
  .ag-header-group-even,
  .ag-col-group-even {
    background-color: $even-color;
  }

  .ag-column-hover {
    background-color: var(--ag-column-hover-color);
  }

  .ag-header-cell-text {
    font-size: 11px;
  }

  .ag-header-cell-label,
  .ag-header-group-cell-label {
    justify-content: center;
    height: 100%;
    font-size: 11px;
  }

  .ag-cell-value {
    font-size: 11px;
    text-align: center;
    align-content: center;
  }

  .teal-cell {
    background-color: #3a6156;
    color: white;
  }

  /* High-value flash */
  .high-value {
    color: black; /* Black text during flash */
    animation-name: high_flash;
    animation-duration: 2s;
    animation-fill-mode: forwards; /* Retain final state of animation */
    animation-timing-function: steps(1);
  }

  /* Low-value flash */
  .low-value {
    color: black; /* Black text during flash */
    animation-name: low_flash;
    animation-duration: 2s;
    animation-fill-mode: forwards; /* Retain final state of animation */
    animation-timing-function: steps(1);
  }

  .positive-value {
    color: #09fb9d;
  }

  .negative-value {
    color: #ff6d46;
  }

  .ag-header-cell-comp-wrapper {
    flex: 1;
    justify-content: center;
  }
}

.ag-row-focus {
  background-color: var(--ag-column-hover-color) !important;
}

.ocl-grid {
  //GLOBAL
  --ag-background-color: #12141a;
  --ag-font-family: Noto Sans;
  --ag-grid-size: 3px;
  --ag-borders: none;
  --ag-font-size: 11px;

  //HEADER
  --ag-header-foreground-color: #999999;
  --ag-header-background-color: #12141a;
  --ag-header-column-separator-display: 'block';
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-color: rgba(1, 1, 1, 0.25);

  /* ROW */
  --ag-row-hover-color: rgba(245, 247, 215, 0.15);
  --ag-row-border-style: none;
  --ag-odd-row-background-color: --ag-background-color;

  /* CELL */
  --ag-range-selection-border-color: #4189e8;
  --ag-value-change-value-highlight-background-color: #01a3a4;

  .ag-tenor-column {
    color: var(--ag-header-foreground-color);
    padding-left: 10px;
    font-weight: 700;
  }

  .ag-row-even {
    background-color: $even-color;
  }

  .ag-row:has(.ag-cell.no-content) {
    border: none !important;
    outline: none !important;
  }

  .ag-cell {
    border: none !important;
  }

  .ag-cell.no-content {
    pointer-events: none;
    background-color: black !important;
    border: none !important;
    outline: none !important;
  }

  .ag-cell.june-dec-cell {
    border-bottom: 1px solid grey !important;
    border-top: 1px solid grey !important;
  }

  .ag-cell.june-dec-cell-vertical {
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
  }

  .ag-cell.june-dec-cell-anamoly {
    border-bottom: 1px solid grey !important;
    border-top: 1px solid grey !important;
    border-left: 1px solid grey !important;
    border-right: 1px solid grey !important;
  }

  .ag-pinned-left-cols-container .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell):not(:focus) {
    border-right: 1px solid rgba(1, 1, 1, 0.25) !important;
  }

  .ag-cell:hover {
    background-color: lightyellow; /* Change cell background on hover */
    opacity: 0.5;
  }

  .ag-cell-value {
    font-size: 11px;
    text-align: center;
    align-content: center;
  }

  .ag-cell-label-container {
    text-align: center;
  }
}

@keyframes high_flash {
  0%,
  85% {
    background-color: #0fbb79; /* Flash green */
  }
  100% {
    color: white;
    background-color: #3a6156; /* Revert to stable state */
  }
}

@keyframes low_flash {
  0%,
  85% {
    background-color: #ff6d46; /* Flash red */
  }
  100% {
    color: white;
    background-color: #3a6156; /* Revert to stable state */
  }
}
