body {
  font-family: 'Courier New', Courier, monospace; /* Monospaced font for text file appearance */
}

.table-container {
  width: 100%;
}

.dtd-file {
  white-space: pre-wrap; /* Preserve whitespace formatting */
  font-size: 12px; /* Adjust font size as needed */
  line-height: 1.2; /* Adjust line height for readability */
  padding: 10px; /* Padding for spacing around the content */
  margin: 0; /* Remove default margin */
  background-color: #f5f5f5; /* Light background color */
  overflow-x: auto; /* Horizontal scrolling if necessary */
}
