@import 'simplebar-react/dist/simplebar.min.css';

.simplebar-scrollbar::before {
  border-radius: 5px;
  background-color: #999;
}

.simplebar-track.simplebar-vertical {
  width: 12px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar::before {
  width: 10px;
  left: 0;
}

.simplebar-track.simplebar-horizontal {
  height: 12px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar::before {
  height: 10px;
  top: 0;
}
