@import './utils/variables.scss';

@import './general/font.scss';
@import './general/error.boundary.scss';

@import './scrollbar.scss';

body {
  margin: 0px;
}
