// NOTO SANS JP
////////////////////////////////////
/*! "Noto Sans JP" is licensed under the SIL Open Font License 1.1 by https://www.google.com/get/noto/ */

@font-face {
  font-family: 'Noto Sans JP Light';
  font-style: normal;
  font-weight: 100;
  src: local('Noto Sans CJK JP'), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 300;
  src: local('Noto Sans CJK JP'), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans CJK JP'), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP Medium';
  font-style: normal;
  font-weight: 500;
  src: local('Noto Sans CJK JP'), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans CJK JP'), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 900;
  src: local('Noto Sans CJK JP'), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.otf) format('opentype');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'),
    url(https://themes.googleusercontent.com/static/fonts/opensans/v6/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff) format('TrueType');
}
