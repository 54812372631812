// colors
$col-alto: #dcdcdc;
$blue-40: #5096fe;
$blue-50: #2b80ff;
$blue-60: #0066ff;
$blue-70: #0055d5;
$blue-90: #003380;

$white: #ffffff;
$black: #000000;

$grey-20: #c7c8d2;
$grey-30: #abadbc;
$grey-40: #8f92a5;
$grey-60: #5c5f73;
$grey-70: #4d4f60;
$grey-80: #3d3f4d;
$grey-90: #2e303a;
$grey-100: #1f2026;
$grey: #4d4f60;

$base-10: #21222d;
$base-20: #1d1f28;
$base-30: #181a22;
$base-40: #12141a;
$base-50: #030304;

$slate-100: #242631;
$slate-60: #6b7294;
$slate-70: #595f7b;
$slate-80: #474c63;
$slate-90: #35394a;

$red-40: #f84d63;
$red-50: #f6203c;
$red-60: #df0925;

$green-40: #09fb9d;
$green-60: #028552;

$teal-40: #04f6f6;
$purple-40: #b95aff;

$pink-40: #f65593;
$pink-50: #f42a78;
$pink-60: #e50c60;

$highlight: #15243c;

$orange-50: #ff712b;

$small-mobile: 385px;
$mobile: 600px; // this should be in-sync with the specification in useScreenSize.tsx
$small-tablet: 768px;
$tablet: 1024px;
$desktop-md: 1240px;
$desktop-lg: 1520px;

$font-default: 'Noto Sans JP';
$font-bold: 'Noto Sans JP Bold';
$font-regular: 'Noto Sans JP Regular';
$font-medium: 'Noto Sans JP Medium';
$font-light: 'Noto Sans JP Light';

$mui-disabled-text: #343f47;
