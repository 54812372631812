.main-content.flux-unexpected-error {
  background-color: $base-40;
  color: $white;
  text-align: center;

  @media screen and (max-width: $mobile) {
    height: 100vh;
    padding: 16px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    color: $base-10;
    font-family: 'Noto Sans JP Bold';
    font-size: 344px;
    letter-spacing: 0;
    line-height: 300px;

    margin: -140px 0 38px 0;

    @media screen and (max-width: $mobile) {
      font-size: 100px;
      line-height: 200px;
    }
  }

  h2 {
    font-family: 'Noto Sans JP Medium';
    font-size: 38px;
    letter-spacing: 0;
    line-height: 55px;

    margin: 0 0 23px 0;
  }

  .description {
    display: flex;
    justify-content: center;

    p {
      font-family: 'Noto Sans JP Regular';
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;

      max-width: 633px;
    }
  }
}

#root > .flux-unexpected-error.main-content,
#flux-container > .MuiScopedCssBaseline-root > .flux-unexpected-error.main-content {
  height: 100vh;
}
