/* Countdown CSS */
.countdown {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Adjust the number of columns as needed */
  gap: 10px; /* Adjust the gap as needed */
  text-align: center;
  font-family: Noto Sans, sans-serif; /* Font for the countdown */
  margin: 20px;
}

.countdown-item {
  background-color: #f0f0f0; /* Background color for the items */
  border-radius: 5px; /* Rounded corners for the items */
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for the items */
}

.countdown-number {
  display: block;
  font-size: 3em; /* Size of the numbers */
  font-weight: bold;
  color: #333; /* Color of the numbers */
}

.countdown-label {
  display: block;
  font-size: 1em; /* Size of the labels */
  color: #666; /* Color of the labels */
  margin-top: 10px;
}

.countdown-item-mobile {
  background-color: #f0f0f0; /* Background color for the items */
  border-radius: 5px; /* Rounded corners for the items */
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for the items */
}

.countdown-number-mobile {
  display: block;
  font-size: 1.5em; /* Size of the numbers */
  font-weight: bold;
  color: #333; /* Color of the numbers */
}

.countdown-label-mobile {
  display: block;
  font-size: 0.5em; /* Size of the labels */
  color: #666; /* Color of the labels */
  margin-top: 10px;
}
